import React, { Component } from 'react'
import { graphql } from 'gatsby'
import BlogList from './BlogList'
import BlogCategories from './BlogCategories'
import Layout from '../layout'
import Page from '../Page/Page'

interface Props {
  data: {
    allDatoCmsPost: HQ.Posts
    allDatoCmsCategory: HQ.Categories
  }
  pageContext: any
  classes: any
  location: Location
}

class BlogCategoryPage extends Component<Props> {
  render() {
    const {
      data: {
        allDatoCmsPost: { nodes: posts },
        allDatoCmsCategory: { nodes: categories },
      },
      pageContext: { category, description },
      location,
    } = this.props
    return (
      <Layout location={location}>
        <Page title={category} subtitle={description}>
          <BlogCategories data={categories} currentPage={category} />
          <BlogList data={posts} />
        </Page>
      </Layout>
    )
  }
}

export const query = graphql`
  query BlogCategoryPage($category: String) {
    allDatoCmsPost(filter: { category: { title: { eq: $category } } }) {
      nodes {
        id
        title
        description
        slug
        cover {
          url
        }
        meta {
          publishedAt(formatString: "MMM D, YYYY")
        }
        category {
          color {
            hex
          }
          title
        }
        author {
          avatar {
            fixed(height: 48, width: 48) {
              src
            }
          }
          name
        }
      }
    }
    allDatoCmsCategory {
      nodes {
        id
        title
      }
    }
  }
`

export default BlogCategoryPage
